import {Avatar, Box, Grid, Typography} from "@mui/material";
import {YoutubeSummary} from "../../../../models/YoutubeSummary";
import {YouTube} from "@mui/icons-material";
import MainStepContainer from "../../MainContent/MainStepParts/MainStepContainer";
import MainStepHeader from "../../MainContent/MainStepParts/MainStepHeader";
import dayjs from "dayjs";
import "dayjs/plugin/utc";
import utc from "dayjs/plugin/utc";

interface DetailedYoutubeAccountsProps {
    youtubeAccounts: YoutubeSummary[];
}

const DetailedYoutubeAccounts = (props: DetailedYoutubeAccountsProps) => {
    const generateAccountLayouts = () => {
        dayjs.extend(utc);
        return props.youtubeAccounts.map((acc: YoutubeSummary) => {
            return (
                <Box sx={{ width: 1, mb: {xs: 3, md:4}, ml:{xs: -1, md: 2}}} key={acc.channelId}>
                    <Box sx={{display: "flex"}}>
                        <Avatar sx={{mr: 2}} src={acc.photoUrl} alt={acc.channelName}/>
                        <Box>
                            <Typography variant={'body1'}>{acc.channelName}</Typography>
                            <Typography variant={'caption'}>{acc.subscribers} subscribers</Typography>
                        </Box>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Channel Title</Typography>
                            <Typography variant={'body1'}>{acc.channelName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Username</Typography>
                            <Typography variant={'body1'}>{acc.channelHandle}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Created on</Typography>
                            <Typography variant={'body1'}>{dayjs(acc.createdOn).utc().format("MM/DD/YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Lifetime Views</Typography>
                            <Typography variant={'body1'}>{acc.lifetimeViews}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Videos</Typography>
                            <Typography variant={'body1'}>{acc.totalVideos}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{pt: 2}}>
                            <Typography variant={'caption'}>Subscribers</Typography>
                            <Typography variant={'body1'}>{acc.subscribers}</Typography>
                        </Grid>
                    </Grid>
                </Box>

            );
        });
    };

    return (
        <MainStepContainer>
            <MainStepHeader>
                <YouTube sx={{color: "#E02F2F", pl: 1.5}}/>
                <Typography sx={{p: 1.5}} variant={'body2'}>YouTube</Typography>
            </MainStepHeader>
            <Box sx={{pt: 4, pl: {xs: 2, md:"24px"}, width: "calc(100% - 24px)"}}>
                {generateAccountLayouts()}
            </Box>
        </MainStepContainer>
    );
};

export default DetailedYoutubeAccounts;