import axios, { AxiosPromise } from "axios";

export const getPaydaySummary = (): AxiosPromise => {
    return axios.get(`/api/billing/creator/payday/summary`);
};

export const getCreatorEarning = (): AxiosPromise => {
    return axios.get(`/api/billing/creator/earnings`);
};

export const updatePaymentMethod = (paymentMethodData: unknown): AxiosPromise => {
    return axios.patch(`/api/settings/payments`, paymentMethodData);
  };
  
  export const getPaymentMethod = (): AxiosPromise => {
    return axios.get(`/api/settings/paymentmethod`);
  };