import {YouTube} from "@mui/icons-material";
import {CircularProgress, Paper, Typography} from "@mui/material";
import YoutubeAuthButton from "../../../YoutubeAuthButton/YoutubeAuthButton";

interface InitialYoutubeSelectionProps {
    scopes: string;
    onSuccess: (x: string | undefined) => void;
    loading: boolean;
}

const InitialYoutubeSelection = (props: InitialYoutubeSelectionProps) => {
    return (
        <Paper elevation={2} sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            justifyContent: "space-between",
            alignItems: "center",
            py: {xs:2,md:4},
            maxWidth: {xs: "380px", md:"322px"},
            margin: {xs: "0 auto", md: "0 auto"},
            width: {xs: "100%", md:"auto"},
        }}>
            <YouTube sx={{color: "#E02F2F", fontSize: 60}}/>
            <Typography variant={"body1"} sx={{color: "#20272E99", pt: {xs:0, md:2}}}>Connect</Typography>
            <Typography sx={{pb: 3, pt: .5}} variant={"h4"}>YouTube</Typography>
            {props.loading ? <CircularProgress/> : <YoutubeAuthButton
                scopes={props.scopes}
                buttonLabel={"CONNECT YOUTUBE"}
                onSuccess={props.onSuccess}
                buttonVariant={"contained"}
                buttonSize={"medium"}
                loginType={"youtube"}
            />}
        </Paper>
    );
};

export default InitialYoutubeSelection;