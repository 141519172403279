import { Grid } from "@mui/material";
import { useContext } from "react";
import { CurrentResponsiveMenuFlag } from "../../hooks/useResponsiveFlag";
import AppSideMenu from "../AppSideMenu";

const AppResponsiveMenu = () => {
    const {responsiveMenuFlag} = useContext(CurrentResponsiveMenuFlag);
    return (
        <>
            {responsiveMenuFlag ? 
                <Grid container width={1} sx={{position: "absolute", top: "40px", display:{xs: "block", md: "none"}}}>
                    <Grid item sx={{bgcolor: "#0a2ae7", minHeight: "calc(100vh - 40px)", width: 1, zIndex: 1}}>
                        <AppSideMenu/>
                    </Grid>
                </Grid>
            :<></>}
        </>
    );
};

export default AppResponsiveMenu;
