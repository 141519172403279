import axios, { AxiosPromise } from "axios";
import { ACCESS_TOKEN } from "../utils/constants/login";
import { User } from "@models/User";

export const getUserInfo = (): AxiosPromise => {
  return axios.get(`/api/v1/user/me`, {
    headers: { Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` },
  });
};

export const getCreatorInfo = (): AxiosPromise => {
  return axios.get(`/api/profile/creator`);
};

export const updateCreatorInfo = (creator: any): AxiosPromise => {
  return axios.patch(`/api/profile/creator`, creator);
};

export const getContracts = (): AxiosPromise => {
  return axios.get(`/api/profile/contracts`);
};

export const getDownloadData = (): AxiosPromise => {
  return axios.get(`/api/settings/download`, {
    responseType: 'blob',
  });
};

export const getAllusers = (): AxiosPromise => {
  return axios.get(`/api/v1/user/all`);
};

export const updateUser = (user: User): AxiosPromise => {
  return axios.put(`/api/v1/user`, user);
};

export const softDeleteUser = (user: User): AxiosPromise => {
  return axios.post(`/api/v1/user`, user);
};

export const addUser = (user: User): AxiosPromise => {
  return axios.post(`/api/v1/user/add`, user);
};

export const loginGoogle = (token: string): AxiosPromise => {
  return axios.post(
    `/api/login`,
    {},
    {
      headers: {
        "X-GOOGLE-RES": `${token}`,
      },
    }
  );
};

export const getGoogleEndpoint = (): AxiosPromise => {
  return axios.get("/api/v1/login/google");
};

export const getUserIntercomHash = (): AxiosPromise => {
  return axios.get("/api/support/intercom-hash");
};

export const getUserTalentManager = (): AxiosPromise => {
  return axios.get("/api/profile/talentmanager");
};