import { Grid, Paper, Typography } from "@mui/material";
import { CallMadeOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { PaymentSummary } from "@models/PaymentSummary";
import { getPaydaySummary } from "services/PaymentServices";
import { AxiosResponse } from "axios";

const Resume = () => {
  const [paymentSummary, setPaymentSummary] = useState<PaymentSummary | null>(null);
  
  const formatDate = (noFormatDate: string | null) => {
    if (noFormatDate === null) {
      return "about to be set";
    }
    const date = new Date(`${noFormatDate}T00:00:00`);
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  const formatAmount = (noFormatAmount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(noFormatAmount);
  };
  const formatPercentage = (noFormatPercentage: number) => {
    return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(noFormatPercentage);
  };

  useEffect(() => {
    getPaydaySummary().then((res: AxiosResponse<PaymentSummary>) => {
      res.data.lastPayday = formatDate(res.data.lastPayday);
      res.data.nextPayday = formatDate(res.data.nextPayday);
      setPaymentSummary(res.data);
    });
  }, []);

  return (
    <Grid item width={1} className="resume-container">
      <Paper sx={{ p: 3, br: 0.5, width: "100%", height: "fit-content",  mb: "3", boxSizing: "border-box" }}>
        <Grid container>
          <Grid item xs={12}>
            <p style={{fontSize: "20px", margin: "0"}}>Next Payday is <b>{paymentSummary?.nextPayday}</b></p>
            <p style={{fontSize: "48px", fontWeight: "600", margin: 0}}>
              {formatAmount(paymentSummary?.nextPayment ? paymentSummary?.nextPayment : 0)}
              <span className="earning-tag  ${paymentSummary?.yearOverYearGrowth && paymentSummary?.yearOverYearGrowth < 0 ? 'negative': ''}" style={{marginLeft: "8px", fontWeight: "400", verticalAlign: 'middle'}}>
                {formatPercentage(paymentSummary?.monthOverMonthGrowth ? paymentSummary?.monthOverMonthGrowth : 0)}
                {
                  paymentSummary?.monthOverMonthGrowth && paymentSummary?.monthOverMonthGrowth < 0 ? 
                  <CallMadeOutlined sx={{fontSize: "10px", ml: .5, verticalAlign: "middle", transform: "rotate(180deg)"}}/> :
                  <CallMadeOutlined sx={{fontSize: "10px", ml: .5, verticalAlign: "middle"}}/>
                }
              </span>
            </p>
          </Grid>
          <Grid item xs={6} sx={{mt: 4}}>
            <Typography variant="base">LAST PAYDAY WAS {paymentSummary?.lastPayday ? paymentSummary?.lastPayday.toUpperCase():'-'}</Typography>
            <br/>
            <Typography variant="base20">
              {formatAmount(paymentSummary?.lastPayment ? paymentSummary?.lastPayment : 0)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{mt: 4}}>
            <Typography variant="base">TOTAL EARNINGS THIS YEAR</Typography>
            <br/>
            <Typography variant="base20">
              {formatAmount(paymentSummary?.totalEarningsThisYear ? paymentSummary?.totalEarningsThisYear : 0)}
              <span className={`earning-tag ${paymentSummary?.yearOverYearGrowth && paymentSummary?.yearOverYearGrowth < 0 ? 'negative': ''}`} style={{marginLeft: "8px", fontWeight: "400", verticalAlign: 'middle'}}>
                {formatPercentage(paymentSummary?.yearOverYearGrowth ? paymentSummary?.yearOverYearGrowth : 0)}
                {
                  paymentSummary?.yearOverYearGrowth && paymentSummary?.yearOverYearGrowth < 0 ? 
                  <CallMadeOutlined sx={{fontSize: "10px", ml: .5, verticalAlign: "middle", transform: "rotate(180deg)"}}/> :
                  <CallMadeOutlined sx={{fontSize: "10px", ml: .5, verticalAlign: "middle"}}/>
                }
              </span>
            </Typography>
          </Grid>
          </Grid>
      </Paper>
    </Grid>
  );
};

export default Resume;
