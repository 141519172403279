import {Grid, useTheme}  from "@mui/material";
import {useLastStep} from "../../../hooks/useLastStep";
import Logo from "../../Logo/logo";
import Header from "../../MainPanel/Header/Header";

const MainHeader = () => {
    const {lastStep} = useLastStep();
    const theme = useTheme();
    const colors = theme.palette.primary;
    return (
        <Grid container sx={{height: {xs: 48, md: 80}, overflow:{xs:"auto",md:"unset"}, pb:{xs:"68px",md:"0"}, maxHeight: "100%"}}>
            <Grid item xs={12} md={3} sx={{borderRight: "1px solid #3550F71F", flexBasis:{xs:"min-content", md:"100%"}}}>
                <Grid item sx={{
                        borderBottom: "1px solid #3550F71F", 
                        display: "flex", 
                        width:{xs: "100vw", md: "auto"}, 
                        position: {xs: "fixed", md: "relative"},
                        top: {xs: 0, md: "auto"},
                        left: {xs: 0, md: "auto"},
                        zIndex:2}}>
                    <Logo sx={{
                        bgcolor: {xs: colors.main, md: colors.contrastText}, 
                        width:{xs: "100vw", md:"100%"},
                        fill: {xs: colors.contrastText, md: colors.main}, 
                        pl:{xs: "16px", md:"40px"}}}/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={lastStep ? 12 : 9}>
                <Grid item sx={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid #3550F71F", width: "100%"}}>
                    {lastStep ? 
                    <Logo sx={{
                        bgcolor: {xs: colors.main, md: colors.contrastText}, 
                        width:{xs: "100vw", md:"auto"},
                        fill: {xs: colors.contrastText, md: colors.main}, 
                        pl:{xs: "16px", md:"40px"}}}/> : <></>}
                    <Header/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MainHeader;