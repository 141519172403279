import { AccountBalanceWalletOutlined, CallMadeOutlined, FileOpenOutlined, InfoOutlined, MonetizationOnOutlined } from "@mui/icons-material";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";

const Media = () => {
  return (
    <Grid item width={1}>
        <Paper sx={{ p: 3, br: 0.5, width: "100%", height: "fit-content",  mb: "3", boxSizing: "border-box" }}>
          <Typography variant="base20">
            Media
            <span style={{verticalAlign: 'text-bottom'}}>
              <InfoOutlined sx={{color: '#AAADB0', verticalAlign: 'middle', display: 'inline-block', ml: 1}}/>
            </span>
          </Typography>
          <br />
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <CallMadeOutlined sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
            You received $300 of extra money from the Wendy&apos;s media campaign that Bent Pixels sold and you were included on March 18th
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              Now
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <MonetizationOnOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              For every $ in rev shares to us you made $3 from BP Reserved Media campaigns in March 2023! Nice going!
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              Now
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <FileOpenOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              The Wendy’s paid media and sponsorship campaigns ended. The campaign report is ready for you!”
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              8h
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <AccountBalanceWalletOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              Your payment for Adsense has gone out to your wallet on March 15th
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              12h
            </Typography>
          </Box>
        </Paper>
    </Grid>
  );
};

export default Media;
