import { Check } from "@mui/icons-material";
import { Button, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography}  from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import Ach from "../../../../../components/icons/Ach";
import Paypal from "../../../../../components/icons/Paypal";
import Qenta from "../../../../../components/icons/Qenta";
import Wire from "../../../../../components/icons/Wire";
import { result, update } from "lodash";
import { getPaymentMethod, updatePaymentMethod } from "services/PaymentServices";

const options = [
    { value: 'WALLET', label: 'Wallet', icon: <Qenta />},
    { value: 'PAYPAL', label: 'Paypal', icon: <Paypal sx={{width: "15px", height: "20px"}}/> },
    { value: 'WIRE', label: 'Wire Transfer', icon: <Wire />},
    { value: 'ACH', label: 'ACH', icon: <Ach />},
    { value: 'CHECK', label: 'Check', icon: <Check />},
];

const PaymentMethod = () => {
    const [selectedOption, setSelectedOption] = useState('WALLET');
    const [paypalEmail, setPaypalEmail] = useState('');

    const [wireNumber, setWireNumber] = useState('');
    const [wireSwift, setWireSwift] = useState('');
    const [wireName, setWireName] = useState('');
    
    const [achRoutingNumber, setAchRoutingNumber] = useState('');
    const [achAccountNumber, setAchAccountNumber] = useState('');
    
    const [checkName, setCheckName] = useState('');
    const [checkAddress, setCheckAddress] = useState('');
    const [checkCity, setCheckCity] = useState('');
    const [checkState, setCheckState] = useState('');
    const [checkPostalCode, setCheckPostalCode] = useState('');

    const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setSelectedOption(event.target.value);
    };
    const getFormatdata = (selectedOption: string) => {
        let resultData = {};
        switch (selectedOption) {
            case "WALLET":
                resultData = {
                    "type": "WALLET"
                };
                break;
            case "PAYPAL":
                resultData = {
                    "type": "PAYPAL",
                    "bp_paypalemail": paypalEmail
                };
                break;
            case "WIRE":
                resultData = {
                    "type": "WIRE",
                    "bp_bankaccountnumber": wireNumber,
                    "bp_bankswift": wireSwift,
                    "bp_bankaccountholdername": wireName
                };
                break;
            case "ACH":
                resultData = {
                    "type": "ACH",
                    "bp_achroutingnumber": achRoutingNumber,
                    "bp_achaccountnumber": achAccountNumber
                };
                break;
            case "CHECK":
                resultData = {
                    "type": "CHECK",
                    "bp_checkpayeename": checkName,
                    "bp_checkaddress": checkAddress,
                    "bp_checkcity": checkCity,
                    "bp_checkstate": checkState,
                    "bp_checkpostalcode": checkPostalCode,
                };
                break;
            }
        return resultData;
    };
    const saveSettings = () => {
        const data = getFormatdata(selectedOption);
        updatePaymentMethod(data);
    };

    useEffect(() => {
        getPaymentMethod().then((res: any) => {
            setSelectedOption(res.data.type);
            setPaypalEmail(res.data.bp_paypalemail);
            setWireNumber(res.data.bp_bankaccountnumber);
            setWireSwift(res.data.bp_bankswift);
            setWireName(res.data.bp_bankaccountholdername);
            setAchRoutingNumber(res.data.bp_achroutingnumber);
            setAchAccountNumber(res.data.bp_achaccountnumber);
            setCheckName(res.data.bp_checkpayeename);
            setCheckAddress(res.data.bp_checkaddress);
            setCheckCity(res.data.bp_checkcity);
            setCheckState(res.data.bp_checkstate);
            setCheckPostalCode(res.data.bp_checkpostalcode);
        });
    },[]);

    return (
        <>
        <Grid container sx={{br: "4px", border: "1px solid #E0E0E0"}}>
            <Grid item xs={12} md={8} sx={{p: "12px 16px"}}>
                <Typography variant="base20">
                    Edit payment method
                </Typography>
                <br />
                <Typography variant="base" sx={{display: "inline-block", lineHeight: "166%"}}>
                    It doesn’t matter which payment method you choose, we promise you’ll have a smooth experience on our platform.
                </Typography>
                <br />
                <FormControl fullWidth sx={{mt: 2}}>
                    <InputLabel style={{ backgroundColor: 'white' }}>Payment method</InputLabel>
                    <Select value={selectedOption} onChange={handleChange}>
                        {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemIcon sx={{minWidth: "24px", mr: 1, display: "inline-flex", verticalAlign: "middle", justifyContent: "center", div: {display: "flex"}}}>
                                {option.icon}
                            </ListItemIcon>
                            <ListItemText primary={option.label} sx={{display: "inline-block", verticalAlign: "middle"}}/>
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedOption === "PAYPAL" ? 
                    <TextField sx={{mt: 3}} id="paypalEmail" fullWidth label="Paypal email (mandatory)"
                    variant="outlined"
                    value={paypalEmail}
                    onChange={(event) => setPaypalEmail(event.target.value)} />
                    : <></>}
                {selectedOption === "WIRE" ?
                <>
                    <TextField sx={{mt: 3}} id="bankAccountNumber" 
                    value={wireNumber}
                    onChange={(event) => setWireNumber(event.target.value)} fullWidth label="Bank account number / IBAN (mandatory)" placeholder="(ex: AA11 2222 3333 4444 5555XX)" variant="outlined" />
                    <TextField sx={{mt: 3}} id="swift" 
                    value={wireSwift}
                    onChange={(event) => setWireSwift(event.target.value)} fullWidth label="Bank swift (mandatory)" placeholder="(ex: AAAA BB CC DDD)" variant="outlined" />
                    <TextField sx={{mt: 3}} id="paypalEmail" 
                    value={wireName}
                    onChange={(event) => setWireName(event.target.value)} fullWidth label="Bank account holder name (mandatory)" placeholder="(ex: Jane Smith)" variant="outlined" />
                </>  
                :<></>}
                {selectedOption === "ACH" ?
                <>
                    <TextField sx={{mt: 3}} id="routingNumber" 
                    value={achRoutingNumber}
                    onChange={(event) => setAchRoutingNumber(event.target.value)} fullWidth label="Routing number (mandatory)" placeholder="(ex: 123456789)" variant="outlined" />
                    <TextField sx={{mt: 3}} id="achAccountNumber"
                    value={achAccountNumber}
                    onChange={(event) => setAchAccountNumber(event.target.value)} fullWidth label="Account number (mandatory)" placeholder="Enter your account number" variant="outlined" />
                </>  
                :<></>}
                {selectedOption === "CHECK" ?
                <>
                    <TextField sx={{mt: 3}} id="checkName"
                    value={checkName}
                    onChange={(event) => setCheckName(event.target.value)} fullWidth label="Routing number (mandatory)" placeholder="(ex: 123456789)" variant="outlined" />
                    <TextField sx={{mt: 3}} id="achAccountNumber"
                    value={checkAddress}
                    onChange={(event) => setCheckAddress(event.target.value)} fullWidth label="Account number (mandatory)" placeholder="Enter your account number" variant="outlined" />
                    <TextField sx={{mt: 3}} id="routingNumber"
                    value={checkCity}
                    onChange={(event) => setCheckCity(event.target.value)} fullWidth label="Routing number (mandatory)" placeholder="(ex: 123456789)" variant="outlined" />
                    <FormControl fullWidth sx={{mt: 3}}>
                        <InputLabel id="state-label">City (mandatory)</InputLabel>
                        <Select 
                            value={checkState}
                            onChange={(event) => setCheckState(event.target.value)} label="City">
                            <MenuItem key={1} value={"ca"}>
                                <ListItemText primary={"(ex: CA)"} sx={{display: "inline-block", verticalAlign: "middle"}}/>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <TextField sx={{mt: 3}} id="achAccountNumber"
                    value={checkPostalCode}
                    onChange={(event) => setCheckPostalCode(event.target.value)} fullWidth label="Account number (mandatory)" placeholder="Enter your account number" variant="outlined" />
                </>  
                :<></>}
            </Grid>
            <Grid item xs={12} md={4} sx={{textAlign: "right", mb: 2, pr: 2, boxSizing: "border-box"}}>
                <Button variant="text" color="inherit" sx={{fontSize: "12px", p: "4px 8px", mt: 1}}>Cancel</Button>
                <Button variant="outlined" color="success" sx={{fontSize: "12px", p: "4px 8px", ml:1, mt: 1}} onClick={saveSettings}>Save</Button>
            </Grid>
        </Grid>
        </>
    );
};

export default PaymentMethod;