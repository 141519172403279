import {Grid, Paper} from "@mui/material";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppHeader from "../../components/AppHeader";
import Unlogged from "./Unlogged";
import WalletConfirm from "./WalletConfirm";
import {useIsAuthenticated} from 'react-auth-kit';

function LoginAB() {
    const provisionalFlag = true; 
    const isAuthenticated = useIsAuthenticated();
    if(isAuthenticated()) {
        location.href = '/home/dashboard';
        return (<></>);
    } else {
        const token = localStorage.getItem('authToken');
        if (token) {
            localStorage.removeItem('authToken');
        }
        console.log('===>', token);
    return (
        <>
            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    boxSizing: "border-box",
                    background: "#0A2AE714",
                    height: {xs:"auto", md:"100%"},
                    overflow: {xs:"auto",md:"unset"},
                    pb: {xs:"68px",md:"0"},
                    maxHeight: "100%"}}>
                <AppHeader/>
                <Grid item xs={11} md={8}
                      sx={{
                          height: "calc(100vh - 81px)",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignContent: "center",
                          position: "relative",
                      }}>
                    <Paper sx={{
                        width: "70%",
                        maxWidth: "784px",
                        padding: "40px",
                        textAlign: "center",
                        margin: "0 auto"
                    }}>
                        {provisionalFlag ? <Unlogged/>:<WalletConfirm/>}
                    </Paper>
                    <AppFooter sx={{position: "absolute", bottom: 2, textAlign: "center", width: "100%"}} />
                </Grid>
            </Grid>
        </>
    );}
}

export default LoginAB;
