import { ChevronLeft } from "@mui/icons-material";
import { Box, Paper, Typography}  from "@mui/material";
import { useContext } from "react";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";

const DataPrivacy = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Data & privacy
        </Typography>
        <Paper sx={{p: 3, br: 0.5}} >
            <Paper sx={{background: "rgba(10, 42, 231, 0.08)", p:2, mb: 1, borderRadius: 0}}>
                <Typography variant="base20" sx={{}}>
                    How we use your data
                </Typography>
            </Paper>
            <Box sx={{p:2}}>
                <Typography variant="base20" sx={{display: "flex", justifyContent: "space-between", }}>
                Personal information
                </Typography>
                <Typography variant="baseCaption" sx={{color: "#20272E", lineHeight: "143%", ul : { pb: 0, pl: "22px", marker: { fontSize: "12px" }}}}>
                    This is the information we collect from you directly. It is used to:
                    <ul>
                        <li>Communicate with you</li>
                        <li>Pay you</li>
                        <li>Verify your identity for contracts and tax documents</li>
                    </ul>
                </Typography>
            </Box>
            <Box sx={{p:2}}>
                <Typography variant="base20" sx={{display: "flex", justifyContent: "space-between", }}>
                    Social Account Data
                </Typography>
                <Typography variant="baseCaption" sx={{color: "#20272E", lineHeight: "143%", ul : { pb: 0, pl: "22px", marker: { fontSize: "12px" }}}}>
                    This is the information we collect from social media platforms with your permission. It is used to:
                    <ul>
                        <li>Power analytics tools for you and your network</li>
                        <li>Help your network package you for brand campaigns and other opportunities</li>
                    </ul>
                </Typography>
            </Box>
        </Paper>
        </>
    );
};

export default DataPrivacy;