import {Avatar, Box, CircularProgress, Typography} from "@mui/material";
import {YoutubeSummary} from "../../../../models/YoutubeSummary";
import YoutubeAuthButton from "../../../YoutubeAuthButton/YoutubeAuthButton";
import {DeleteOutline, YouTube} from "@mui/icons-material";
import MainStepContainer from "../../MainContent/MainStepParts/MainStepContainer";
import MainStepHeader from "../../MainContent/MainStepParts/MainStepHeader";
import DeleteAccount from "./DeleteAccount";
import {useState} from "react";
import {SnackBarControlType} from "../YoutubeSnackBarAlert";

interface SelectedYoutubeAccountsProps {
    youtubeAccounts: YoutubeSummary[];
    scopes: string;
    onSuccess: (x: string | undefined) => void;
    removeAccount: (channelId: string) => void;
    loading: boolean;
    setDeleteAlertOpen: (open: boolean) => void;
    setDeleteAlertDetails: (snackControl: SnackBarControlType) => void;
}

const SelectedYoutubeAccounts = (props: SelectedYoutubeAccountsProps) => {
    const [open, setOpen] = useState(false);
    const [idToRemove, setIdToRemove] = useState("");

    const handleModalClose = (action: string, shouldDelete: boolean) => {
        if (shouldDelete) {
            props.removeAccount(idToRemove);
            props.setDeleteAlertOpen(true);
            props.setDeleteAlertDetails({
                message: "Account has been deleted!",
                variant: "filled",
                severity: "success"
            });
        }
        setIdToRemove("");
        setOpen(false);
    };

    const deleteRecord = (id: string) => {
        setIdToRemove(id);
        setOpen(true);
        props.setDeleteAlertDetails({
            message: "Account has been deleted!",
            variant: 'filled',
            severity: 'success'
        });
    };

    const generateAccountLayouts = () => {
        return props.youtubeAccounts.map((acc, id) => {
            return (
                <Box sx={{display: "flex", alignItems: "center", width: 1, justifyContent: "space-between", mb: 4}} key={acc.channelId+id}>
                    <Box sx={{display: "flex"}}>
                        <Avatar sx={{mr: 2}} src={acc.photoUrl} alt={acc.channelName}/>
                        <Box>
                            <Typography variant={'body1'}>{acc.channelName}</Typography>
                            <Typography variant={'caption'}>{acc.subscribers} subscribers</Typography>
                        </Box>
                    </Box>
                    <DeleteOutline sx={{color: "#F52238", cursor: "pointer", pr: 2}} onClick={() => deleteRecord(acc.channelId)}/>
                </Box>
            );
        });
    };

    return (
        <MainStepContainer>
            <DeleteAccount open={open} handleClose={handleModalClose} />
            <MainStepHeader>
                <YouTube sx={{color: "#E02F2F", pl: 1.5}}/>
                <Typography sx={{p: 1.5}} variant={'body2'}>YouTube</Typography>
            </MainStepHeader>
            <Box sx={{pt: 4, pl: {xs: 2, md:"24px"}, width: "calc(100% - 24px)"}}>
                {generateAccountLayouts()}
                {props.loading ? <CircularProgress/> : <YoutubeAuthButton
                    scopes={props.scopes}
                    buttonLabel={"ADD MORE ACCOUNTS"}
                    onSuccess={props.onSuccess}
                    buttonVariant={"text"}
                    buttonSize={"small"}
                    loginType={"youtube"}
                />}
            </Box>
        </MainStepContainer>
    );
};

export default SelectedYoutubeAccounts;