import {ReactNode} from "react";
import {Box} from "@mui/material";

const MainStepHeader = (props: {children: ReactNode}) => {
    return (
        <Box sx={{display: "flex", alignItems: "center", backgroundColor: "rgba(53, 80, 247, 0.04)", width: 1}}>
            {props.children}
        </Box>
    );
};

export default MainStepHeader;