import {Box, Grid, Typography} from "@mui/material";
import {BentPixelsUserInfo} from "@models/BentPixelsUserInfo";
import MainStepContainer from "../MainContent/MainStepParts/MainStepContainer";
import {countries, CountryType} from "../../../models/Countries";
import {languages, LanguageType} from "../../../models/Languages";
import {useEffect, useState} from "react";
import {genders, GenderType} from "../../../models/Genders";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

interface UserInfoReviewProps {
    userInfo: BentPixelsUserInfo
}

const UserInfoReview = (props: UserInfoReviewProps) => {
    const {userInfo} = props;
    const [language, setLanguage] = useState<LanguageType>({code: "", flag: "", label: "", numCode: 0});
    dayjs.extend(utc);

    const lookupCountryName = (): string => {
        const country: CountryType | undefined = countries.find((country) => country.code === userInfo.country);
        if (country !== undefined) {
            return country.label;
        }
        return "";
    };

    const lookupGender = (): string => {
        const gender: GenderType | undefined = genders.find((gender) => gender.code === userInfo.gender);
        if (gender !== undefined) {
            return gender.label;
        }
        return "";
    };

    useEffect(() => {
        const foundLanguage: LanguageType | undefined = languages.find((lang) => lang.numCode === userInfo.language);
        if (foundLanguage !== undefined) {
            setLanguage(foundLanguage);
        }
    }, [userInfo.language]);

    return (
        <MainStepContainer>
            <Box sx={{width: 1, mb: 3, ml: {xs: 1, md:3}, mr: {xs: 2, md:3}}}>
                <Grid container>
                    <Grid item xs={12} sx={{pt: 2}}>
                        <Typography variant={"h6"}>Basic Information</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>First Name</Typography>
                        <Typography variant={'body1'}>{userInfo.firstName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Last Name</Typography>
                        <Typography variant={'body1'}>{userInfo.lastName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Email</Typography>
                        <Typography variant={'body1'}>{userInfo.email}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Country</Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <img
                                loading="lazy"
                                width="30"
                                height="17"
                                src={`https://flagcdn.com/w20/${userInfo.country.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${userInfo.country.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            <Typography sx={{pl: 1}} variant={'body1'}>{lookupCountryName()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 5}}>
                        <Typography variant={"h6"}>Personal Information</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Gender</Typography>
                        <Typography variant={'body1'}>{lookupGender()}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Language</Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <img
                                loading="lazy"
                                width="30"
                                height="17"
                                src={`https://flagcdn.com/w20/${language.flag.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${language.flag.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            <Typography sx={{pl: 1}} variant={'body1'}>{language.label}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Date of Birth</Typography>
                        <Typography variant={'body1'}>{dayjs(userInfo.dob).utc().format("MM/DD/YYYY")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pt: 2}}>
                        <Typography variant={'caption'}>Phone Number</Typography>
                        <Typography variant={'body1'}>{userInfo.phoneNumber}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </MainStepContainer>
    );
};

export default UserInfoReview;