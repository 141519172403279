import "./App.scss";
import {Alert, Snackbar, ThemeProvider} from "@mui/material";
import {AuthProvider, RequireAuth} from "react-auth-kit";
import {CurrentStepContext, useStep} from "./hooks/useStep";
import {CurrentResponsiveMenuFlag, useResponsiveMenuFlag} from "./hooks/useResponsiveFlag";
import {BentPixelsTheme} from "./theme";
import Onboarding from "./Onboarding";
import axios, {AxiosRequestConfig} from "axios";
import GoogleCompliance from "./GoogleCompliance";
import LoginAB from "./pages/Login/LoginAB";
import Home from "./pages/Home";
import {
    Routes,
    Route,
} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CurrentResponsiveInsideFlag, useResponsiveInsideFlag } from './hooks/useResponsiveInsideFlag';
import { useContext } from "react";
import { CurrentUserInfo } from "utils/hooks/useUserInfo";
import {useMainSnackbar, CurrentMainSnackbar} from "./hooks/useMainSnackbar";

function App() {
    const {step, updateStep} = useStep();
    const {responsiveMenuFlag, setResponsiveMenuFlag} = useResponsiveMenuFlag();
    const {responsiveInsideFlag, setResponsiveInsideFlag} = useResponsiveInsideFlag();
    const {isOpen, type, message, updateMainSnackbar, setIsOpen} = useMainSnackbar();

    const { user, logout } = useContext(CurrentUserInfo);
    
    const handleClose = () => {
        setIsOpen(false);
    };
    
    if (process.env.NODE_ENV === 'development') {
        axios.defaults.baseURL = 'http://localhost:8081';
    }
    
    const auth = localStorage.getItem('_auths');
    if (auth && !window.location.pathname.includes('/onboarding')) {
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            // @ts-ignore
            config.headers.Authorization = `Bearer ${auth}`;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    }

    return (
            <div className="App" style={{overflow: responsiveMenuFlag ? "hidden":"auto", background: "rgba(10, 42, 231, 0.08)",}}>
                <AuthProvider authType = {'localstorage'}
                  authName={'_auths'}>
                    <ThemeProvider theme={BentPixelsTheme}>
                        <CurrentStepContext.Provider value={{step, updateStep}}>
                            <CurrentResponsiveInsideFlag.Provider value={{responsiveInsideFlag, setResponsiveInsideFlag}}>
                            <CurrentResponsiveMenuFlag.Provider value={{responsiveMenuFlag, setResponsiveMenuFlag}}>
                            <CurrentMainSnackbar.Provider value={{isOpen, type, message, updateMainSnackbar}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <Routes>
                                        <Route path="/onboarding" element={<Onboarding/>} />
                                        <Route path="/google-services-compliance" element={<GoogleCompliance/>} />
                                        <Route path="/" element={<LoginAB/>}></Route>
                                        <Route path="/home/*" element={
                                            <RequireAuth loginPath={'/'}>
                                                <Home/>
                                            </RequireAuth>
                                        }></Route>
                                    </Routes>
                                    <Snackbar
                                        open={isOpen}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                        autoHideDuration={4000}
                                        onClose={handleClose}>
                                            <Alert severity={type} sx={{ width: '100%' }}>
                                                {message}
                                            </Alert>
                                        </Snackbar>
                                </LocalizationProvider>
                            </CurrentMainSnackbar.Provider>
                            </CurrentResponsiveMenuFlag.Provider>
                            </CurrentResponsiveInsideFlag.Provider>
                        </CurrentStepContext.Provider>
                    </ThemeProvider>
                </AuthProvider>
            </div>
    );
}

export default App;
