import axios, {AxiosResponse} from "axios";
import {YouTubeResponse} from "../../../models/YoutubeResponse";
import {YoutubeSummary} from "../../../models/YoutubeSummary";
import InitialYoutubeSelection from "./InitialYoutubeSelection/InitialYoutubeSelection";
import SelectedYoutubeAccounts from "./SelectedYoutubeAccounts/SelectedYoutubeAccounts";
import AccountExists from "./AccountExists";
import AccountBlacklisted from "./AccountBlacklisted";
import RequiredChannels from "./RequiredChannels";
import {useEffect, useState} from "react";
import {useSearchParams} from 'react-router-dom';
import YoutubeSnackBarAlert, {SnackBarControlType} from "./YoutubeSnackBarAlert";
import {YoutubeAnalyticsResponse} from "@models/YoutubeAnalyticsResponse";
import dayjs from "dayjs";

interface YoutubeProps {
    addAccount: (account: YouTubeResponse, analytics: YoutubeAnalyticsResponse) => void;
    youtubeAccounts: YoutubeSummary[];
    requiredChannels: YoutubeSummary[];
    removeAccount: (channelId: string) => void;
    canProceedToNextStep: (enabled: boolean) => void;
}

const Youtube = (props: YoutubeProps) => {
    const channelAuditScope = "https://www.googleapis.com/auth/youtubepartner-channel-audit";
    const youtubeReadonlyScope = "https://www.googleapis.com/auth/youtube.readonly";
    const youtubeAnalyticsScope = "https://www.googleapis.com/auth/yt-analytics.readonly";
    const scopes = `${channelAuditScope} ${youtubeReadonlyScope} ${youtubeAnalyticsScope}`;
    const [accountExists, setAccountExists] = useState(false);
    const [accountBlacklisted] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackControl, setSnackControl] = useState<SnackBarControlType>({
        message: "Account has been deleted!",
        variant: 'filled',
        severity: 'success'
    });

    const creatorApplicationId = searchParams.get('applicationId');
    const closeAccountExistsModal = (action: string) => {
        if (action !== 'backdropClick') {
            setAccountExists(false);
            setAccountName("");
            setLoading(false);
        }
    };
    const closeAccountBlacklistedModal = (action: string) => {
        if (action !== 'backdropClick') {
            setAccountExists(false);
            setAccountName("");
            setLoading(false);
        }
    };

    useEffect(() => {
            if (props.requiredChannels.length > 0) { // custom link application: at minimum, must select all of the expected/required YT channels
                const selectedChannelIds = props.youtubeAccounts.map(acct => acct.channelId);
                const requiredChannelIds = props.requiredChannels.map(c => c.channelId);
                // mark off any channels from the required list
                props.canProceedToNextStep(requiredChannelIds.every(c => selectedChannelIds.includes(c)));
            } else { // no required channels (brand new application or custom with no pre-determined channels): only requirement is to select at least 1 channel
                props.canProceedToNextStep(props.youtubeAccounts.length > 0);
            }
        }, [props.youtubeAccounts]
    );

    const onSuccess = (accessToken: string | undefined) => {
        setLoading(true);
        if (accessToken !== undefined) {
            axios.get("https://youtube.googleapis.com/youtube/v3/channels?part=snippet,statistics,auditDetails&mine=true",
                {headers: {Authorization: `Bearer ${accessToken}`}})
                .then((res: AxiosResponse<YouTubeResponse>) => {
                    if (res.data.pageInfo.totalResults !== 0) {
                        axios.get(`/api/social/exists/${res.data.items[0].id}`)
                        // axios.get(`/api/social/exists/UCBQaenmc9luJ2d-lhSxjqlA`)
                            .then((exists: AxiosResponse<string>) => {
                                if (creatorApplicationId != null || exists.status === 204) {
                                    const dimensions = 'day';
                                    const endDate = dayjs().format("YYYY-MM-DD");
                                    const ids = 'channel==MINE';
                                    const metrics = 'views,estimatedMinutesWatched';
                                    const startDate = dayjs().subtract(1, 'year').format("YYYY-MM-DD");
                                    const sort = 'day';
                                    axios.get('https://youtubeanalytics.googleapis.com/v2/reports?', {headers: {Authorization: `Bearer ${accessToken}`}, params: {
                                            dimensions,
                                            endDate,
                                            ids,
                                            metrics,
                                            startDate,
                                            sort
                                        }}).then((analyticsResponse:AxiosResponse<YoutubeAnalyticsResponse>)  => {
                                        props.addAccount(res.data, analyticsResponse.data);
                                        setLoading(false);
                                    });
                                } else {
                                    setAccountExists(true);
                                    setAccountName(res.data.items[0].snippet.title);
                                }
                            });
                    } else {
                        setSnackControl({
                            ...snackControl,
                            message: "Invalid account selected. Please select a youtube account.",
                            severity: "error"
                        });
                        setSnackOpen(true);
                        setLoading(false);
                    }
                });

        } else {
            setLoading(false);
        }

    };

    return (
        <>
            <YoutubeSnackBarAlert snackInfo={snackControl} onClose={setSnackOpen} isOpen={snackOpen}/>
            <AccountExists open={accountExists} handleClose={closeAccountExistsModal} channelName={accountName}/>
            <AccountBlacklisted open={accountBlacklisted} handleClose={closeAccountBlacklistedModal} channelName={accountName}/>
            {props.youtubeAccounts.length === 0 ?
                <InitialYoutubeSelection
                    scopes={scopes}
                    onSuccess={onSuccess}
                    loading={loading}/>
                :
                <SelectedYoutubeAccounts removeAccount={props.removeAccount}
                                         youtubeAccounts={props.youtubeAccounts}
                                         scopes={scopes}
                                         onSuccess={onSuccess}
                                         loading={loading}
                                         setDeleteAlertOpen={setSnackOpen}
                                         setDeleteAlertDetails={setSnackControl}
                />}
            { props.requiredChannels.length > 0 && 
                <RequiredChannels requiredChannels={props.requiredChannels} selectedChannels={props.youtubeAccounts} />
            }
        </>
    );


};

export default Youtube;