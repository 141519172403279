import {useContext, useEffect, useState} from 'react';
import {CurrentStepContext} from "./useStep";
import {steps} from "../components/LeftPanel/StepInstructions/StepInstructionList";

export function useLastStep() {
    const {step} = useContext(CurrentStepContext);
    const [lastStep, setLastStep] = useState(false);
    const [secondToLastStep, setSecondToLastStep] = useState(false);

    useEffect(() => {
        if (step === steps.length - 2) {
            setSecondToLastStep(true);
        } else {
            setSecondToLastStep(false);
        }
        if (step === steps.length - 1) {
            setLastStep(true);
        } else {
            setLastStep(false);
        }
    }, [step]);

    return {lastStep, secondToLastStep};
}
