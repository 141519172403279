import {Alert, Snackbar} from "@mui/material";
import Slide from "@mui/material/Slide";

export interface SnackBarControlType {
    message: string
    variant: 'standard' | 'filled' | 'outlined',
    severity: 'success' | 'info' | 'warning' | 'error'
}

interface YoutubeSnackBarAlertProps {
    snackInfo: SnackBarControlType;
    onClose: (open: boolean) => void;
    isOpen: boolean;
}

const YoutubeSnackBarAlert = (props: YoutubeSnackBarAlertProps) => {
    const {snackInfo, onClose, isOpen } = props;
    const closeSnackbar = () => {
        onClose(false);
    };

    return (
        <>
            <Snackbar
                open={isOpen}
                autoHideDuration={15000}
                onClose={closeSnackbar}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                TransitionComponent={(propss) => <Slide {...propss} direction="down" />}
            >
                <Alert onClose={closeSnackbar} severity={snackInfo.severity} variant={snackInfo.variant}>
                    {snackInfo.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default YoutubeSnackBarAlert;