export interface StepControl {
    stepperName: string
    stepTitle: string
    stepInstruction: string
}

export const steps: StepControl[] = [{
    stepperName: "Connect accounts",
    stepTitle: "Connect Your Social Media Accounts",
    stepInstruction: "In order to process your application, please connect your selected accounts so we can collect and analyse the data about you and your audience.",
},{
    stepperName: "Connect email",
    stepTitle: "Connect Email",
    stepInstruction: "We request some basic information about who you are and how to contact you during the application process.",
},{
    stepperName: "Basic information",
    stepTitle: "Tell Us About Yourself",
    stepInstruction: "We request some basic information about who you are and how to contact you during the application process.",
},{
    stepperName: "You're done!",
    stepTitle: "",
    stepInstruction: "",
}];