import {Button, Grid, Paper, Typography}  from "@mui/material";
import { Link } from "react-router-dom";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext, useEffect, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import { Creator } from "@models/User";
import { getContracts, getCreatorInfo } from "services/UserServices";
import {differenceInMonths} from "date-fns";

const Contracts = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const [creatorInfo, setCreatorInfo] = useState<Creator>({ gender: '', language: '' } as Creator);
    const [contracts, setContracts] = useState<any[]>([]);
    useEffect(() => {
        if (!creatorInfo.emailAddress1) {
            getCreatorInfo().then((res: any) => {
                setCreatorInfo(res.data);
            });
            getContracts().then((res: any) => {
                setContracts(res.data);
            });
        }
    }, []);
    
    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Contracts ({contracts?.length})
        </Typography>
        <Paper sx={{p: {xs: 0, md:3}, br: 0.5, backgroundColor: {xs:"transparent", md: "#fff"}}}>
            <Typography variant="base20" sx={{ display: {xs: "none", sm: "flex"}, mt: 2, mb: 3, justifyContent: "space-between", }}>
                Contracts ({contracts?.length})
            </Typography>
            {
                contracts?.map((contract, index) => {
                    return(
                        <Paper elevation={0} sx={{border: "1px solid #E0E0E0", borderRadius: "4px", p: 2, mt: {md:2, xs: 0}}} key={index}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography variant="base16">
                                    {contract.contractId}
                                </Typography>
                                <br />
                                <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
                                    {contract.numberOfChannels} channel{contract.numberOfChannels > 1 ? "s" : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: "right"}}>
                                <Button variant="outlined" color="success" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, display: "inline-block", padding: "0 8px"}}>Active</Button>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                                    <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Revenue shares</Typography>
                                    {/* <Button component={Link} to="./detail/1109" variant="text" size="small" sx={{position: "absolute", top: 0, right: 0, fontSize: "12px"}}>+4 more</Button> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="base" sx={{textTransform: "uppercase"}}>PartNER Provided</Typography> <br />
                                    <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>{contract.partnerProvidedRevShare}%</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator Paid FEATURES</Typography> <br />
                                    <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>{contract.ucgRevShare}%</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                                    <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Contract terms</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="base" sx={{textTransform: "uppercase"}}>Duration</Typography> <br />
                                    <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>{differenceInMonths(new Date(contract.renewalDate), new Date(contract.startDate))} MONTHS</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="base" sx={{textTransform: "uppercase"}}>termination DATE</Typography> <br />
                                    { contract.autorenewable ? <Typography variant="base16" sx={{color: "rgba(20, 168, 28, 1)", fontWeight: "600", letterSpacing: "0.46px"}}>Auto-renewable</Typography> : <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>FIXED</Typography> }
                                    <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.6)", display: "block"}}>in {differenceInMonths(new Date(contract.renewalDate), new Date())} months</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    );
                }
            )}
            {/* <Paper elevation={0} sx={{border: "1px solid #E0E0E0", borderRadius: "4px", p: 2, mt: 2}}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="base16">
                            1109-10098
                        </Typography>
                        <br />
                        <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
                            1 channel
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: "right"}}>
                        <Button variant="outlined" color="error" size="small" sx={{color: "rgba(245, 34, 56, 1)", borderColor: "rgba(245, 34, 56, 1)", bgColor: "rgba(245, 34, 56, 0.5)", fontSize:"10px", px: 1, py: "4px", mt: 1, display: "inline-block", padding: "0 8px"}}>Expired</Button>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                            <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Revenue shares</Typography>
                            <Button component={Link} to="./detail/1109" variant="text" size="small" sx={{position: "absolute", top: 0, right: 0, fontSize: "12px"}}>+4 more</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>PartNER Provided</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>85.00%</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator Paid FEATURES</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>50.00%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                            <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Contract terms</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>Duration</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>12 MONTHS</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>termination DATE</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>FIXED</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0} sx={{border: "1px solid #E0E0E0", borderRadius: "4px", p: 2, mt: 2}}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="base16">
                            1109-10098
                        </Typography>
                        <br />
                        <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
                            1 channel
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: "right"}}>
                        <Button variant="outlined" color="primary" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, display: "inline-block", padding: "0 8px"}}>To sign</Button>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                            <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Revenue shares</Typography>
                            <Button component={Link} to="./detail/1109" variant="text" size="small" sx={{position: "absolute", top: 0, right: 0, fontSize: "12px"}}>+4 more</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>PartNER Provided</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>85.00%</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator Paid FEATURES</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>50.00%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sx={{position: "relative", mt: "12px", mb: "4px"}}>
                            <Typography variant="body2" sx={{color: "rgba(32, 39, 46, 0.60)", letterSpacing: "0.1px", fontWeight: "500"}}>Contract terms</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>Duration</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>12 MONTHS</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="base" sx={{textTransform: "uppercase"}}>termination DATE</Typography> <br />
                            <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>FIXED</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper> */}
        </Paper>
        </>
    );
};

export default Contracts;