import {Grid, Box, Typography, Paper} from "@mui/material";
import Logo from "./components/Logo/logo";
import AppFooter from "./components/AppFooter/AppFooter";
import {useTheme} from "@mui/material/styles";

function GoogleCompliance() {
    const theme = useTheme();
    const colors = theme.palette.primary;
    return (
        <>
            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    boxSizing: "border-box",
                    background: "#0A2AE714",
                    height: {xs:"auto", md:"100%"},
                    overflow: {xs:"auto",md:"unset"},
                    pb: {xs:"68px",md:"0"},
                    maxHeight: "100%"}}>
                <Grid xs={12} sx={{ borderBottom: "1px solid rgba(10, 42, 231, 0.12)"}}>
                    <Box>
                        <Logo sx={{bgcolor: {xs: colors.main, md: colors.contrastText},
                            width:{xs: "100vw", md:"auto"},
                            fill: {xs: colors.contrastText, md: colors.main},
                            pl:{xs: "16px", md:"40px"}}}/>
                    </Box>
                </Grid>
                <Grid xs={11} md={8}
                      sx={{
                          height: "calc(100vh - 81px)",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignContent: "space-between",
                      }}>
                    <Box sx={{margin: 'auto', mt: {xs: 3, md: 10}, maxWidth: "784px",}}>
                        <Typography variant={"h4"} sx={{fontSize: "34px"}}>Google API Services Limited Use Compliance</Typography>
                        <Typography variant={"body1"} sx={{mt: {xs:3, md:4}}}>
                            Creator Portal’s use and transfer to any other app of information received from
                            Google APIs will adhere to <a style={{textDecoration: "none"}} href={"https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"} target={"_blank"} rel="noreferrer">Google API Services User Data Policy</a>,
                            including the Limited Use requirements.
                        </Typography>
                        <Paper sx={{mt: 4, py: 4, px: {xs: 2, md: 4}}}>
                            <Typography variant={"h6"} sx={{
                                fontWeight: "500",
                                fontSize: "20px",
                                lineHeight: "160%",
                                letterSpacing: "0.15px",
                                color: "#20272E",
                            }}>Limited use</Typography>
                            <Typography variant={"body2"} sx={{mt:4}}>
                                Our app complies with the Google API Services User Data Policy,
                                including the Limited Use requirements:
                            </Typography>
                            <ul style={{
                                fontSize: "14px",
                                lineHeight: "143%",
                                letterSpacing: "0.17px",
                                color: "#20272E",
                                paddingLeft: "20px"
                            }}>
                                <li>
                                    Do not use or transfer the data for serving ads, including retargeting, personalized, or interest-based advertising.
                                </li><li>
                                    Limit your use of data to providing or improving user-facing features that are prominent in the requesting application&apos;s user interface.
                                </li><li>
                                    Transfers of data are allowed only to provide or improve your appropriate access or user-facing features that are visible and prominent in the requesting application&apos;s user interface and only with the user&apos;s consent, for security purposes and to comply with the law as well as in the eventuality of a merger or acquisition, with prior user consent.
                                </li><li>
                                    Do not allow humans to read the user&apos;s data unless you have obtained the user&apos;s affirmative agreement to view specific messages, files, or other data.
                                </li>
                            </ul>
                        </Paper>
                    </Box>
                    <Box sx={{margin: 'auto', maxWidth: "784px", width: "100%"}}>
                        <AppFooter sx={{mt:10}} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default GoogleCompliance;
