import {useContext} from 'react';
import {CurrentStepContext} from "../../../hooks/useStep";
import {Box, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {StepControl, steps} from "../../LeftPanel/StepInstructions/StepInstructionList";

const Header = () => {
    const {step} = useContext(CurrentStepContext);
    return (
        <Box sx={{display: {xs: "none", md: "flex"}, height: 80, alignItems: "center", justifyContent: "center", flexGrow: 1}}>
            <Stepper sx={{width: "78%"}} activeStep={step}>
                {steps.map((stepDetails: StepControl, index) => {
                    return (
                    <Step key={stepDetails.stepperName} completed={step > index}>
                        <StepLabel>
                            <Typography sx={[ step > index && {color: '#14A81C'}]}>
                            {stepDetails.stepperName}
                            </Typography>
                        </StepLabel>
                    </Step>);
                })}
            </Stepper>
        </Box>
    );
};

export default Header;