import {Box, Typography} from "@mui/material";

const AppFooter = (props: {sx?:object | null | undefined}) => {
    const typographyVariant = 'caption';
    return (
        <Box sx={{width: {xs: "100%", md: "110%"}, fontSize: "12px", ...props.sx}} className={'footer'}>
            <Box>
                <Typography variant={typographyVariant} sx={{pr: .5, fontSize:{xs: "10px"}}}>If you need to update your Google and YouTube app
                    permissions
                    click</Typography>
                <a href='https://myaccount.google.com/permissions' style={{fontSize:"10px"}} target="_blank" rel="noreferrer">here</a>
                <Typography variant={typographyVariant} sx={{fontSize:{xs: "10px"}}}>. </Typography>
            </Box>
            <Box sx={{pt: 2, pb: 0}}>
                <Box sx={{fontSize:{xs: "10px", md:"12px"}}}>
                    <Typography variant={typographyVariant} sx={{pr: 1, fontSize:{xs: "10px"}}}>&copy; Bent Pixels 2022</Typography>
                    <a href='https://www.bentpixels.com/terms-of-service/' target="_blank" rel="noreferrer"  style={{fontSize: "10px"}}>Terms of Service</a>
                    <Typography variant={typographyVariant} sx={{pl: 1, pr: 1, fontSize:{xs: "10px"}}} >&#x2022;</Typography>
                    <a href='https://www.bentpixels.com/privacy-policy/' style={{fontSize: "10px"}} target="_blank" rel="noreferrer">Privacy Policy </a>
                    <Typography variant={typographyVariant} sx={{pl: 1, pr: 1, fontSize:{xs: "10px"}}} >&#x2022;</Typography>
                    <a href='/google-services-compliance/' target="_blank" rel="noreferrer"  style={{fontSize: "10px"}}>Google API Services Limited Use Compliance </a>
                </Box>
            </Box>
        </Box>

    );

};

// const ExternalLink = (props: {href: string, displayText: string}) => {
//     return (
//         <Link href={props.href} target='blank' sx={{textDecoration: "none"}}>
//             <Typography variant={"caption"} color={"primary"}>{props.displayText}</Typography>
//         </Link>
//     );
// };

export default AppFooter;