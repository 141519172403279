import {Female, Male, Transgender} from "@mui/icons-material";
import {ReactNode} from "react";

export interface GenderType {
    code: number;
    icon: ReactNode;
    label: string;
}
export const genders: readonly GenderType[] = [
    {code: 2, icon: <Female sx={{pr: 2}} />, label: 'Female'},
    {code: 1, icon: <Male sx={{pr: 2}} />, label: 'Male'},
    {code: 121260000, icon: <Transgender sx={{pr: 2}} />, label: 'Other'},
];