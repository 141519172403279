import {Grid, Paper, Button, Box} from "@mui/material";
import AppHeader from "../../components/AppHeader";
import AppSideMenu from "../../components/AppSideMenu";
import HomeIcon from '@mui/icons-material/Home';
import {
    Routes,
    Route,
    Link,
} from "react-router-dom";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Settings from "./Settings";
import { CurrentResponsiveMenuFlag } from "../../hooks/useResponsiveFlag";
import { AccountBalanceOutlined, AnalyticsOutlined } from "@mui/icons-material";
import AppResponsiveMenu from "../../components/AppResponsiveMenu";
import { useContext } from "react";
import Feedback from "../../components/icons/Feedback";
import {useAuthUser} from 'react-auth-kit';
import { getUserIntercomHash } from "services/UserServices";
import { AxiosResponse } from "axios";

function Home() {
    const {responsiveMenuFlag} = useContext(CurrentResponsiveMenuFlag);
    const authUser = useAuthUser();
    getUserIntercomHash().then((response: AxiosResponse<string>) =>{
        const config = {
            api_base: "https://api-iam.intercom.io",
            app_id: "81307f5eba702180741b9266c8dcd6fd3ffbe4f3",
            user_hash: response.data,
            name: authUser()?.firstName + ' ' + authUser()?.lastName, 
            email: authUser()?.email,
            created_at: Date.now()
        };
        // @ts-ignore
        window.Intercom("boot", config);
        // @ts-ignore
        window.Intercom("update");
    });
    
    const openIntercom = () => {
        // @ts-ignore
        Intercom('show');
    };

    return (
        <>
            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    boxSizing: "border-box",
                    overflow: responsiveMenuFlag ? "none": {xs:"auto",md:"unset"},
                    minHeight: "100%",
                    p: {xs: "16px 0", md: 2},
                    pb: {xs:"78px",md:"96px"},
                    }}>
                <AppHeader/>
                <Grid container spacing={2} columns={15} sx={{mb:{xs: 8, md: 0},width: {sx: "100%", md:"80%"}, maxWidth: "1200px", mt:0, position: "relative", top: {xs: "30px", md: "80px"}, zIndex: "0"}}>
                    <Grid item xs={0} md={4} sx={{display: {xs: "none", md: "block"}}}>
                        <AppSideMenu/>
                    </Grid>
                    <Grid item xs={15} md={11}>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard/>}></Route>
                            <Route path="/profile/*" element={<Profile/>}></Route>
                            <Route path="/settings/*" element={<Settings/>}></Route>
                        </Routes>
                    </Grid>
                </Grid>
                <AppResponsiveMenu/>
                <Paper sx={{display: {xs: "block", md: "none"},width: "100%", position: "fixed", bottom: 0, left: 0, height: "48px", boxShadow: "#e5e5e5 0px -1px 2px"}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button fullWidth sx={{minHeight:"48px"}} component={Link} to="/home/dashboard">
                                <HomeIcon/>
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth sx={{minHeight:"48px", color: "#bdbdbd"}}>
                                <AccountBalanceOutlined/>
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth sx={{minHeight:"48px", color: "#bdbdbd"}}>
                                <AnalyticsOutlined/>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {/* <Box onClick={openIntercom} sx={{display: "flex", cursor: "pointer", justifyContent: "center", position: "absolute", boxSizing: "border-box", bottom: {md: "16px", xs: "64px"}, right: {md: "16px", xs: "16px"}, background: "#fff", width: "48px", height: "48px", borderRadius: "50%", border: "1px solid rgba(0, 0, 0, 0.23)"}}>
                <Feedback sx={{marginTop: "6px"}}></Feedback>
            </Box> */}
        </>
    );
}

export default Home;
