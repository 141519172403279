import { CheckCircle, ChevronLeft } from "@mui/icons-material";
import {Button, Paper, Typography}  from "@mui/material";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Security = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    
    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Security
        </Typography>
        <Paper sx={{p: 3, br: 0.5}}>
            <Paper sx={{background: "#e7f6e8", mb: 1, borderRadius: 0}}>
                <Typography variant="body2" sx={{p:2, color: "#09430b"}}>
                    <CheckCircle sx={{color: "rgba(20, 168, 28, 1)", verticalAlign: "middle", mr: 2}}/>
                    Your account is secured.
                </Typography>
            </Paper>
            <Typography variant="base20" sx={{display: "flex", mt: 2, justifyContent: "space-between", }}>
                Account Recovery
            </Typography>
            <Typography variant="baseCaption" sx={{color: "#20272E", lineHeight: "143%"}}>
                For account recovery assistance, please reach out to our support team. Contact us for prompt assistance.
            </Typography>
            <Button variant="outlined" sx={{width: "100%", mt: 3}} component={Link} to="https://bentpixels.com/about#contact" target="_blank">
                Contact Us
            </Button>
        </Paper>
        </>
    );
};

export default Security;