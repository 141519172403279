import { Paper } from "@mui/material";
import DiscordIcon from "../DiscordIcon";
import SkypeIcon from "../SkypeIcon";
import { useEffect, useState } from "react"; // Import useState
import { User } from "@models/User";
import { AxiosResponse } from "axios";
import { getUserTalentManager } from "services/UserServices";
import { TalentManager } from "@models/TalentManager";

const TalentManagerCard = (props: {sx?:object | null | undefined}) => {
    const [talentManager, setTalentManager] = useState<TalentManager | null>(null);

    useEffect(() => {
      getUserTalentManager().then((res: AxiosResponse<TalentManager>) => {
        setTalentManager(res.data);
      });
    }, []);
    
    return (
        <Paper sx={{p: 4, mt: 3, borderRadius: {xs: 0, md:0.5}, pb: {xs:"48px", md:5}}}>
            <ul className="profile-info" style={{...props.sx}}>
                <li>
                    <p className="title">
                        Talent Manager 
                    </p>
                    <p className="name">{talentManager?.fullName ? talentManager?.fullName : '-'}</p>
                </li>
                <li>
                    <p className="title">
                        Email
                    </p>
                    <p style={{overflow: "hidden", textOverflow: "ellipsis"}} className="value">{talentManager?.email ? talentManager?.email : '-'}</p>
                </li>
                <li>
                    <p className="title">
                        Phone
                    </p>
                    <p style={{overflow: "hidden", textOverflow: "ellipsis"}} className="value">{talentManager?.phoneNumber ? talentManager?.phoneNumber : '-'}</p>
                </li>
                <li>
                    <p className="title">
                        Discord
                    </p>
                    <div style={{overflow: "hidden", textOverflow: "ellipsis"}} className="value">
                        {talentManager?.discordId ? talentManager?.discordId : '-'}
                        <DiscordIcon sx={{position: "absolute", right: "0", top: "-2px", color: "#20272E"}}/>
                    </div>
                </li>
                <li>
                    <p className="title">
                        Skype
                    </p>
                    <div style={{overflow: "hidden", textOverflow: "ellipsis"}} className="value">
                        {talentManager?.skypeId ? talentManager?.skypeId : '-'}
                        <SkypeIcon sx={{position: "absolute", right: "0", top: "-2px", color: "#20272E"}}/>
                    </div>
                </li>
            </ul>
        </Paper>
    );
};

export default TalentManagerCard;