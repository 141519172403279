import {useContext} from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {CurrentStepContext} from "../../../hooks/useStep";
import {steps} from "./StepInstructionList";
import InfoReviewSelector from "./InfoReviewSelector/InfoReviewSelector";


const StepInstructions = () => {
    const {step} = useContext(CurrentStepContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{pb: 2, pt: {xs: 2, md:10}, pl: {xs: 2, md:5}, background:"#FFF"}}>
            {step === 2 && isSmallScreen ?
                <InfoReviewSelector/>
                : <></>
            }
            <Typography sx={{pb: {xs:1, md:3}, pr: 5, fontSize:{xs: 16, md: 24}, lineHeight:{xs:1.6, md:1.2}}} variant='h4'>{steps[step].stepTitle}</Typography>
            <Typography variant='body1' sx={{pr: 5, fontSize:{xs: 14, md:16}}}>{steps[step].stepInstruction}</Typography>
            {step === 2 && !isSmallScreen?
                <InfoReviewSelector />
                : <></>
            }
        </Box>
    );
};

export default StepInstructions;