import { Paper, Button, Chip } from "@mui/material";

const ShortsAds = () => {
    return (
        <>
            <Paper sx={{br: .5, background: "#E91E63", p: 3, color: "#fff", mb: 3, w: "100%"}}>
                <Chip label="Offer" size="small" sx={{color: "#fff", fontSize: "12px" }}/>
                <p style={{fontSize: "22px", fontWeight: "600", padding: "0px 38px 0px 0px", position: "relative"}}>
                    Start using shorts today to get a 10% increase in subscribers!
                    <span style={{position: "absolute", top: "0", right: "-6px", fontSize: "32px"}}>
                        📈
                    </span>
                </p>
                <Button variant="outlined" size="small" sx={{color: "#fff", borderColor:"#fff", padding: "6px 24px", fontWeight: "600"}}>
                    Get Started
                </Button>
            </Paper>
        </>
    );
};

export default ShortsAds;