import {useEffect, useState} from "react";
import {YoutubeSummary} from "../models/YoutubeSummary";
import {YouTubeResponse} from "../models/YoutubeResponse";
import {YoutubeAnalyticsResponse} from "../models/YoutubeAnalyticsResponse";

const useYoutubeAccounts = () => {
    const [youtubeAccounts, setYoutubeAccounts] = useState<YoutubeSummary[]>([]);
    const [recToAdd, setRecToAdd] = useState<{account: YouTubeResponse, analytics: YoutubeAnalyticsResponse} | undefined>();

    const addAccount = (account: YouTubeResponse, analytics: YoutubeAnalyticsResponse) => {
        setRecToAdd({account, analytics});
    };

    useEffect(() => {
        if (recToAdd !== undefined) {
            if (recToAdd.account.items?.length > 0) {
                const {account} = recToAdd;
                if (youtubeAccounts.findIndex((summary) => summary.channelId === account.items[0].id) === -1) {
                    const summary = {
                        channelAddress: `https://youtube.com/${account.items[0].snippet.customUrl}`,
                        channelId: account.items[0].id,
                        channelName: account.items[0].snippet.title,
                        channelHandle: account.items[0].snippet.customUrl,
                        photoUrl: account.items[0].snippet.thumbnails.default.url,
                        photoWidth: account.items[0].snippet.thumbnails.default.width,
                        photoHeight: account.items[0].snippet.thumbnails.default.height,
                        createdOn: account.items[0].snippet.publishedAt,
                        lifetimeViews: account.items[0].statistics.viewCount,
                        subscribers: account.items[0].statistics.subscriberCount,
                        totalVideos: account.items[0].statistics.videoCount,
                        overallGoodStanding: account.items[0].auditDetails.overallGoodStanding,
                        communityGuidelinesGoodStanding: account.items[0].auditDetails.communityGuidelinesGoodStanding,
                        copyrightStrikesGoodStanding: account.items[0].auditDetails.copyrightStrikesGoodStanding,
                        contentIdClaimsGoodStanding: account.items[0].auditDetails.contentIdClaimsGoodStanding,
                        watchTimeLastTwelveMonths: calculateLastTwelveMonthsWatchTimeInHours(recToAdd.analytics),
                        viewsLastThirtyDays: calculateLast30days(recToAdd.analytics),
                    };
                    setYoutubeAccounts((current) => {
                        return [...current, summary];
                    });
                }
            }
        }
    }, [recToAdd]);

    const calculateLastTwelveMonthsWatchTimeInHours = (analytics: YoutubeAnalyticsResponse): number => {
        let total = 0;
        analytics.rows.forEach((row) => {
            if (typeof row[1] === "number") {
                total += row[1];
            } else {
                console.error("was not a number" , typeof row[1]);
            }
        });
        return total;
    };

    const calculateLast30days = (analytics: YoutubeAnalyticsResponse): number => {
        let last30days = analytics.rows;
        if (analytics.rows.length > 30){
            last30days = analytics.rows.splice(-30);
        }
        let total = 0;
        last30days.forEach((row) => {
            if (typeof row[1] === "number") {
                total += row[1];
            } else {
                console.error("was not a number" , typeof row[1]);
            }
        });
        return total;
    };

    const removeAccount = (channelId: string) => {
        setYoutubeAccounts(youtubeAccounts.filter((item) => item.channelId !== channelId));
    };

    return {addAccount, removeAccount, youtubeAccounts};
};

export default useYoutubeAccounts;