import {Box} from "@mui/material";

const Wire = (props: {sx?:object | null | undefined}) => {
    return (
        <Box sx={{...props.sx}}>
            <svg role="img" aria-label="Bent Pixels Wire" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>Wire icon</title>
                <path d="M4.83556 16.23C3.70778 15.15 3 13.6575 3 12C3 9.2025 4.98333 6.8625 7.66667 6.195V7.7625C5.85444 8.3775 4.55556 10.0425 4.55556 12C4.55556 13.245 5.08444 14.3625 5.92444 15.18L7.66667 13.5V18H3L4.83556 16.23Z" fill="black" fillOpacity="0.54"/>
                <path d="M16.3333 6H21L19.1644 7.77C20.2922 8.85 21 10.3425 21 12C21 14.7975 19.0167 17.1375 16.3333 17.805V16.2375C18.1456 15.6225 19.4444 13.9575 19.4444 12C19.4444 10.755 18.9156 9.6375 18.0756 8.82L16.3333 10.5V6Z" fill="black" fillOpacity="0.54"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7972 15.1772V16H12.3706V15.178C12.8581 15.1553 13.2835 15.0719 13.6469 14.9277C14.0804 14.7566 14.4138 14.507 14.6469 14.1789C14.88 13.8508 14.9977 13.45 15 12.9766C14.9977 12.7023 14.9534 12.4492 14.8671 12.2172C14.7832 11.9852 14.6515 11.7766 14.472 11.5914C14.2925 11.4039 14.0618 11.241 13.7797 11.1027C13.4977 10.9645 13.1585 10.8531 12.7622 10.7688L12.3706 10.6844V9.42755C12.5419 9.45704 12.6783 9.51504 12.7797 9.60156C12.9359 9.73281 13.0233 9.91563 13.042 10.15H14.9021C14.8998 9.67891 14.7832 9.26758 14.5524 8.91602C14.324 8.56211 13.9988 8.28789 13.5769 8.09336C13.2282 7.93256 12.8261 7.83822 12.3706 7.81034V7H11.7972V7.80968C11.3437 7.83652 10.9346 7.9299 10.5699 8.08984C10.134 8.28203 9.79254 8.55039 9.54545 8.89492C9.3007 9.23711 9.17949 9.63672 9.18182 10.0938C9.17949 10.6562 9.36014 11.1004 9.72378 11.4262C10.0897 11.7496 10.5897 11.9805 11.2238 12.1188L11.7972 12.2431V13.5647C11.6594 13.5465 11.5347 13.5133 11.4231 13.4652C11.2459 13.3879 11.1084 13.2742 11.0105 13.1242C10.9126 12.9742 10.8578 12.7891 10.8462 12.5687H9C9.00233 13.1711 9.13054 13.6656 9.38462 14.0523C9.64103 14.4367 10 14.7215 10.4615 14.9066C10.8509 15.0621 11.2961 15.1523 11.7972 15.1772ZM12.3706 13.5585C12.4488 13.5457 12.5199 13.5275 12.5839 13.5039C12.7238 13.4523 12.8298 13.3809 12.9021 13.2895C12.9744 13.198 13.0117 13.0937 13.014 12.9766C13.0117 12.8664 12.9755 12.7703 12.9056 12.6883C12.838 12.6039 12.7261 12.5289 12.5699 12.4633C12.5112 12.4377 12.4448 12.4129 12.3706 12.3886V13.5585ZM11.7972 10.5443V9.42407C11.7175 9.43637 11.6464 9.45452 11.5839 9.47852C11.458 9.52539 11.3648 9.58984 11.3042 9.67187C11.2436 9.75391 11.2121 9.84766 11.2098 9.95313C11.2051 10.0398 11.2203 10.1184 11.2552 10.1887C11.2925 10.2566 11.3508 10.3187 11.4301 10.375C11.5093 10.4289 11.6107 10.4781 11.7343 10.5227C11.7546 10.53 11.7756 10.5372 11.7972 10.5443Z" fill="black" fillOpacity="0.54"/>
            </svg>
        </Box>
    );
};
export default Wire;