import {Box, Button, Dialog, Typography} from "@mui/material";

interface DeleteAccountProps {
    open: boolean;
    handleClose: (s: string, shouldDelete: boolean) => void;
}
const DeleteAccount = (props: DeleteAccountProps) => {
    const {open , handleClose} = props;
    return (
        <Dialog
            open={open}
            onClose={(event: object, reason: string) => handleClose(reason, false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{py: 2, px: 3}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Delete account
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3, mb: 2 }}>
                    Are you sure you want to delete the account? If you delete the account, we won’t be able to get any relevant data and use it in our process
                </Typography>
                <Button sx={{float: "right",background:"#F52238"}} variant={"contained"} onClick={() => handleClose("buttonClick", true)}>DELETE</Button>
                <Button sx={{float: "right", mr: 3}} variant={"text"} onClick={() => handleClose("buttonClick", false)}>CANCEL</Button>
            </Box>
        </Dialog>
    );
};

export default DeleteAccount;