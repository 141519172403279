import {ReactNode} from "react";
import {Paper} from "@mui/material";

const MainStepContainer = (props: { children?: ReactNode }) => {
    return (
            <Paper elevation={2} sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                p: 1,
                width: 1,
                boxSizing: "border-box"
            }}>
                {props.children}
            </Paper>
    );
};

export default MainStepContainer;