import { AssistantPhotoOutlined, InfoOutlined, ThumbUpAltOutlined, VerifiedOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";

const Branded = () => {
  return (
    <Grid item width={1}>
        <Paper sx={{ p: 3, br: 0.5, width: "100%", height: "fit-content",  mb: "3", boxSizing: "border-box" }}>
          <Typography variant="base20">
            Branded
            <span style={{verticalAlign: 'text-bottom'}}>
              <InfoOutlined sx={{color: '#AAADB0', verticalAlign: 'middle', display: 'inline-block', ml: 1}}/>
            </span>
          </Typography>
          <br />
          <Box sx={{my:2, position: 'relative'}}>
            <Grid container sx={{width: "100%", background: "#0091EA", borderRadius: "4px", minHeight: "140px"}}>
              <Grid item xs={9} sx={{p: 3}}>
                <Typography variant="base" sx={{color: "#fff", letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
                  Other creators in your segment are due to making 12K each this month from branded content opportunities.
                </Typography>
                <Button variant={"outlined"} sx={{mt: 2, color: "#fff", borderColor:"#fff", padding: "6px 24px", fontWeight: "600"}}>
                  Get in on it now
                </Button>
              </Grid>
              <Grid item xs={3}>
                <div style={{borderRadius: "0 4px 4px 0", boxSizing:"border-box", background: "url(/img/creator-banner.png) center center / cover", width: "100%", height: "100%", padding: "24px 24px 24px 0"}}>.</div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <AssistantPhotoOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              You have been selected for the Dell campaign RFP. We’ll keep you updated!
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              Now
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <ThumbUpAltOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              Thank you for submitting live the material for the FDA campaign! Great work!”
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              2h
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{my:2, pl: 5, position: 'relative'}}>
            <VerifiedOutlined  sx={{position: 'absolute', left: '0', top: '4px', color: '#90A4AE'}}/>
            <Typography variant="base" sx={{letterSpacing: '0.17px', lineHeight: '143%', display: 'inline-block'}}>
              Feedback available for the Honda campaign submitted material. Looking forward to the modifications.
            </Typography>
            <br />
            <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.38)"}}>
              1w
            </Typography>
          </Box>
      </Paper>
      <br />
    </Grid>
  );
};

export default Branded;
