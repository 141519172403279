import {Grid}  from "@mui/material";
import {
    Routes,
    Route,
} from "react-router-dom";
import Security from "./Security";
import DataPrivacy from "./DataPrivacy";
import DownloadYourData from "./DownloadYourData";
import PaymentMethod from "./PaymentMethod";
import SideMenu from "./SideMenu";
import { useContext } from "react";
import { CurrentResponsiveInsideFlag } from "../../../hooks/useResponsiveInsideFlag";

const Settings = () => {
    const {responsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    return (
        <>
        <Grid container spacing={2} sx={{mb: 2}}>
            <Grid item xs={12} md={4} sx={{display: responsiveInsideFlag ? {xs:"none", md: "block"}: "block"}}>
                <SideMenu/>
            </Grid>
            <Grid item xs={12} md={8} sx={{display: !responsiveInsideFlag ? {xs:"none", md: "block"}: "block"}}>
                <Routes>
                    <Route path="/security" element={<Security/>}></Route>
                    <Route path="/data-and-privacy" element={<DataPrivacy/>}></Route>
                    <Route path="/download-your-data" element={<DownloadYourData/>}></Route>
                    <Route path="/payment-method" element={<PaymentMethod/>}></Route>
                </Routes>
            </Grid>
        </Grid>
        </>
    );
};

export default Settings;