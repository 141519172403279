import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from "axios";
import MainStepHeader from "../MainContent/MainStepParts/MainStepHeader";
import {PersonOutline} from "@mui/icons-material";
import {Box, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import MainStepContainer from "../MainContent/MainStepParts/MainStepContainer";
import {BentPixelsUserInfo} from "../../../models/BentPixelsUserInfo";
import CountryDropDown from "../../CountryDropDown/CountryDropDown";
import GenderDropDown from "../../Gender/GenderDropDown";
import LanguagesDropDown from "../../Languages/LanguagesDropDown";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs, {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AsYouType, CountryCode} from "libphonenumber-js";
import {DateValidationError} from "@mui/x-date-pickers/internals";

interface UserInfoStepsProps {
    emailAccessToken: string;
    canSubmit: (enabled: boolean) => void;
    userInfo: BentPixelsUserInfo;
    setUserInfo: (value: (((prevState: BentPixelsUserInfo) => BentPixelsUserInfo) | BentPixelsUserInfo)) => void;
    fetchData: boolean;
    setFetchData: (x: boolean) => void;
    disableAllFields?: boolean;
    setExistingAccount: (existing: boolean) => void;
}

const UserInfoSteps = (props: UserInfoStepsProps) => {
    const {disableAllFields = false} = props;
    const [dob, setDob] = useState<Dayjs | null>(null);
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const [dateError, setDateError] = useState(' ');
    const {emailAccessToken, userInfo, setUserInfo, fetchData, setFetchData} = props;

    useEffect(() => {
        props.canSubmit(false);
    }, [props.canSubmit]);


    useEffect(() => {
        if (fetchData && !userInfo.creatorId) {
            axios.get(`/api/creator/${userInfo.email}/exists`)
                .then((creatorRes: AxiosResponse<BentPixelsUserInfo>) => {
                    if (creatorRes.status === 200) {
                        setUserInfo(creatorRes.data);
                        props.setExistingAccount(true);
                    } else {
                        const bpUser = {
                            ...userInfo, firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                            email: userInfo.email,
                            photo: userInfo.photo
                        };
                        setUserInfo(bpUser);
                    }
                    setFetchData(false);
                });
        } else if (fetchData && userInfo.creatorId) {
            console.log(userInfo);
            console.log(userInfo.dob);
            props.setExistingAccount(true);
            setFetchData(false);
        }
        if (userInfo.dob !== '') {
            console.log(dayjs(userInfo.dob), '<<dob');
            setDob(dayjs(userInfo.dob));
        }
    }, [emailAccessToken, fetchData]);

    const updateFirstName = (name: string) => {
        setUserInfo(prevState => {
            return {...prevState, firstName: name};
        });
    };

    const updateLastName = (name: string) => {
        setUserInfo(prevState => {
            return {...prevState, lastName: name};
        });
    };

    const updateSelectedCountry = (name: string) => {
        setUserInfo(prevState => {
            return {...prevState, country: name};
        });
    };

    const updateGender = (gender: number) => {
        setUserInfo(prevState => {
            return {...prevState, gender: gender};
        });
    };

    const updateLanguage = (lang: number) => {
        setUserInfo(prevState => {
            return {...prevState, language: lang};
        });
    };

    const updatePhone = (phone: string) => {
        const pn = new AsYouType(userInfo.country.toUpperCase() as CountryCode).input(phone);
        setUserInfo(prevState => {
            return {...prevState, phoneNumber: pn};
        });
    };

    useEffect(() => {
        updatePhone(userInfo.phoneNumber);
    }, [userInfo.country]);

    const hasBeenTouched = (value: string): boolean => {
        return touchedFields.findIndex((field) => field === value) > -1;

    };
    const touchedField = (value: string) => {
        if (!hasBeenTouched(value)) {
            setTouchedFields(current => [...current, value]);
        }
    };

    // enable submit button if all fields are filled in with something.
    useEffect(() => {
        if (userInfo.gender !== -1 &&
            userInfo.phoneNumber !== '' &&
            userInfo.country !== '' &&
            userInfo.email !== '' &&
            userInfo.language !== 0 &&
            userInfo.lastName !== '' &&
            userInfo.firstName !== '' &&
            userInfo.dob !== '' && dateError === ' ' ) {
            props.canSubmit(true);
        } else {
            props.canSubmit(false);
        }
    }, [userInfo]);


    const isPhoneInvalid = (): boolean => {
        return userInfo.phoneNumber === '' && hasBeenTouched('phone');
    };

    const isCountryInvalid = (): boolean => {
        return userInfo.country === '' && hasBeenTouched('country');
    };

    const isGenderInvalid = (): boolean => {
        return userInfo.gender === -1 && hasBeenTouched('gender');
    };

    const isLanguageInvalid = (): boolean => {
        return userInfo.language === 0 && hasBeenTouched('language');
    };

    const isLastNameInvalid = (): boolean => {
        return userInfo.lastName === '' && hasBeenTouched('lastname');
    };

    const isFirstNameInvalid = (): boolean => {
        return userInfo.firstName === '' && hasBeenTouched('firstname');
    };

    const isDobInvalid = (): boolean => {
        return (userInfo.dob === '' || dateError !== ' ') && hasBeenTouched('dob');
    };

    const updateDob = (date: Dayjs | null) => {
        if (date === null) {
            setDateError('Required');
        } else {
            setDateError(' ');
        }
        setDob(date);
        try {
            //converting to an ISOstring will throw if it is not a valid date.
            if (date?.toISOString()) {
                setUserInfo(prevState => {
                    return {...prevState, dob: date.toISOString()};
                });
            }
        } catch (e) {
            setUserInfo(prevState => {
                return {...prevState, dob: ''};
            });
        }
    };

    const updateDateHelperText = (err: DateValidationError) => {
        switch (err) {
            case 'invalidDate': {
                setDateError("Invalid Date");
                break;
            }
            case 'disableFuture': {
                setDateError("Birth date can't be future date");
                break;
            }
            case 'minDate': {
                setDateError("You can't be that old");
                break;
            }
            case null: {
                setDateError(' ');
                break;
            }
            default: {
                break;
            }
        }
    };

    return (
        <MainStepContainer>
            {disableAllFields ? <></> :
                <MainStepHeader>
                    <PersonOutline sx={{color: "rgba(0, 0, 0, 0.54)", pl: 1.5}}/>
                    <Typography sx={{p: 1.5}} variant={'body2'}>Please fill out the following form in order to complete
                        your
                        application to Bent Pixels. All fields are mandatory.</Typography>
                </MainStepHeader>
            }
            {fetchData ? <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", mx: 2}}>
                    <Typography variant={"h4"} sx={{mt: 4}} >Checking for existing account</Typography>
                    <CircularProgress sx={{my: 6}}/>
            </Box>
                :
                <Grid container sx={{width: 1, justifyContent: "flex-start", mt: {xs:3, md:6}, mb: {xs:0, md:5}, pl: {xs:1, md:4}, pr:{xs: 1, md:0}}}>
                    <Grid item xs={12} sx={{pb: 2}}>
                        <Typography variant={"h6"}>Basic Information</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pr: {xs:0,md:2}}}>
                        <TextField
                            label={"First Name"}
                            variant={"outlined"}
                            fullWidth
                            value={userInfo.firstName}
                            onChange={(event) => updateFirstName(event.target.value)}
                            error={isFirstNameInvalid() ? true : undefined}
                            helperText={isFirstNameInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('firstname')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pl: {xs:0,md:2}, pr: {xs:0, md:4}}}>
                        <TextField
                            label={"Last Name"}
                            variant={"outlined"}
                            fullWidth
                            value={userInfo.lastName}
                            onChange={(event) => updateLastName(event.target.value)}
                            error={isLastNameInvalid() ? true : undefined}
                            helperText={isLastNameInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('lastname')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 4, pr: {xs:0, md:2}}}>
                        <TextField
                            label={"Email"}
                            variant={"outlined"}
                            fullWidth
                            value={userInfo.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pl: {xs:0,md:2}, pr: {xs:0, md:4}}}>
                        <CountryDropDown
                            initialValue={userInfo.country}
                            updateValue={updateSelectedCountry}
                            error={isCountryInvalid() ? true : undefined}
                            helperText={isCountryInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('country')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{pb: 2, pt: {xs: 0, md:5}}}>
                        <Typography variant={"h6"}>Personal Information</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pr: {xs:0,md:2}}}>
                        <GenderDropDown
                            initialValue={userInfo.gender}
                            updateValue={updateGender}
                            error={isGenderInvalid() ? true : undefined}
                            helperText={isGenderInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('gender')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pl: {xs:0,md:2}, pr: {xs:0, md:4}}}>
                        <LanguagesDropDown
                            initialValue={userInfo.language}
                            updateValue={updateLanguage}
                            error={isLanguageInvalid() ? true : undefined}
                            helperText={isLanguageInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('language')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pr: {xs:0,md:2}}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Date of Birth"
                                inputFormat="MM/DD/YYYY"
                                value={dob}
                                onChange={(e) => updateDob(e)}
                                onError={(e: DateValidationError) => updateDateHelperText(e)}
                                disableFuture
                                disabled={disableAllFields}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    error={isDobInvalid() ? true : undefined}
                                    helperText={dateError}
                                    onBlur={() => {
                                        touchedField('dob');
                                        updateDob(dob);
                                    }}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pb: 2, pl: {xs:0,md:2}, pr: {xs:0, md:4}}}>
                        <TextField
                            label={"Phone Number"}
                            value={userInfo.phoneNumber}
                            onChange={(event) => updatePhone(event.target.value)}
                            type={"tel"}
                            fullWidth
                            placeholder={"e.g. +1 000 000 0000"}
                            error={isPhoneInvalid() ? true : undefined}
                            helperText={isPhoneInvalid() ? 'Required' : ' '}
                            onBlur={() => touchedField('phone')}
                            disabled={disableAllFields}
                        />
                    </Grid>
                </Grid>
            }
        </MainStepContainer>
    );

};

export default UserInfoSteps;