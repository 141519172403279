export interface LanguageType {
    code: string;
    flag: string;
    label: string;
    numCode: number;
}


export const languages: readonly LanguageType[] = [
    {code: "EN", flag: "us", label: "English", numCode: 121260000,},
    {code: "FR", flag: "fr", label: "French", numCode: 121260002,},
    {code: "DE", flag: "de", label: "German", numCode: 121260004,},
    {code: "PT", flag: "pt", label: "Portuguese", numCode: 121260003,},
    {code: "ES", flag: "es", label: "Spanish", numCode: 121260001,},
];